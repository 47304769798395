import { render, staticRenderFns } from "./GenerateRoyaltyReport.vue?vue&type=template&id=28bcb703&scoped=true"
import script from "./GenerateRoyaltyReport.vue?vue&type=script&lang=js"
export * from "./GenerateRoyaltyReport.vue?vue&type=script&lang=js"
import style0 from "./GenerateRoyaltyReport.vue?vue&type=style&index=0&id=28bcb703&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bcb703",
  null
  
)

export default component.exports